.home {
  h2 {
    text-transform: uppercase;
    font-size: 3.5rem;
  }
  .SliceContact {
    margin-top: 8em;
    opacity: .95;
    h2 {
      text-align: center;
    }
    form {
      label,
      input,
      textarea {
        display: block;
        width:100%;
      }
      label {
        margin-bottom: 1.5em;
      }
    }
  }
  .SliceAction {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center;
    h3 {
      margin-bottom: 1em;
      text-transform: uppercase;
    }
    p {
      text-align: center;
      margin-bottom: 2.5em;
    }
    a {
      font-size: 20px;
      color: #fff;
      border: solid 2px #fff;
      padding: .5em 2em;
    }
    .overlay {
      background-color: rgba(16,8,6,.4);
      padding: 16em 0;
    }
  }
  .SliceVideo {
    padding-top: 4em;
    h2, h3 {
      text-align: center;
    }
    h2 {
      margin-bottom: 3rem;
  
    }
    .videoWrapper{
      overflow:hidden;
      // padding-bottom:41.85%;
      position:relative;
      height:0;
      iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
      }
      margin-bottom: 2em;
    }
  }

  .SliceArtist,
  .SliceAlbum {
    padding: 10em 0 2em;
    h2, h3 {
      text-align: center;
    }
    h2 {
      margin-bottom: 3rem;
  
    }
    img {
      max-width: 100%;
    }
    .artist,
    .album {
      margin-bottom: 2em;
    }
  }
}
