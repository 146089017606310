.album {
  img {
    max-width: 100%;
  }
  .songRow {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
  }
}
