@import "~bootswatch/dist/darkly/variables";

$font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$navbar-nav-link-padding-x: 1em;
$link-color: $white;
$link-decoration: none;
// $grid-gutter-width: 60px;
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

// $fa-font-path: "./fonts/fa";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "~react-image-gallery/styles/scss/image-gallery.scss";

$rhap_background-color: transparent;
@import '~react-h5-audio-player/src/styles.scss';

@import './spinner';
@import './loading';
@import './nav';
@import './home';
@import './artist';
@import './album';

body {
  padding-bottom: 5em;
}

h1, h2, h3 {
  font-family: 'Abel', sans-serif;
}

.nav-spacer {
  margin-top: 10em;
}

.bodyText {
  p {
    line-height: 1.8em;
    margin-bottom: 1.4em;
  }
}

.market-places {
  // color: #000;
  margin-top: .5em;
  text-align: center;
  a {
    color: #000;
    background-color: #bbb;
    font-size: 18px;
    padding: 5px 10px;
    margin-right: .5em;
    display: inline-block;
    border-radius: 5px;
  }
  .fab,
  .fas {
    // padding: 10px;
  }
}

.footer {
  text-align:center;
  padding: 8em 0 1em;
}

.songRow {
  .songName {
    font-weight: bold;
  }
  .artistName,
  .artistName a {
    color: #999;
  }
}

.react-h5-audio-player {
  opacity: 0.2;
  > .flex {
    padding: 5px 0 !important;
    height: 50px !important;
  }
  > div {
    background-color: transparent !important;
  }
  .toggle-play-wrapper {
    .toggle-play-button {
      // Remember to use !important to overwrite inline styles.
      background-color: #fff !important;
      .play-icon {
        //background-color: #000 !important;
        border-left-color: #000 !important;
      }
    }
  }
  .indicator {
    background-color: #fff !important;
    // background: #fff !important;
  }
}