.navbar {
  background-color: #222;
}
.navbar-brand {
  img {
    width: 100px;
    height: auto;
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    background-color: transparent;
  }
  .navbar-nav > .nav-link {
    line-height: 80px;
    height: 80px;
  }
  .navbar {
    .navbar-text,
    .nav-link {
      font-weight: bold;
      text-transform: uppercase;
    }
    .navbar-brand {
      img {
        width: auto;
        height: 80px;
      }
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
