.artist {
  .videoWrapper{
    overflow:hidden;
    // padding-bottom:41.85%;
    position:relative;
    height:0;
    iframe{
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
    margin-bottom: 2em;
  }
  .headshot {
    margin-bottom: 2em;
  }
  img {
    max-width: 100%;
  }
  .artistSidebar {
    .songRow {
      //padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #333;
    }
    .albumRow {
      //padding-top: 10px;
      padding-bottom: 1em;
    }
  }

}
